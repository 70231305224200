const bg1 = "/backgrounds/bg-1.jpg";
const bg2 = "/backgrounds/bg-2.jpg";
const bg3 = "/backgrounds/bg-3.jpg";
const bg4 = "/backgrounds/bg-4.jpg";
const bg5 = "/backgrounds/bg-5.jpg";
const bg6 = "/backgrounds/bg-6.jpg";
const bg7 = "/backgrounds/bg-7.jpg";
const bg8 = "/backgrounds/bg-8.jpg";
const bg9 = "/backgrounds/bg-9.jpg";
const bg10 = "/backgrounds/bg-10.jpg";
const bg11 = "/backgrounds/bg-11.jpg";
const bg12 = "/backgrounds/bg-12.jpg";
const bg13 = "/backgrounds/bg-13.jpg";
const bg14 = "/backgrounds/bg-14.jpg";
const bg15 = "/backgrounds/bg-15.jpg";
const bg16 = "/backgrounds/bg-16.jpg";
const bg17 = "/backgrounds/bg-17.jpg";
const bg18 = "/backgrounds/bg-18.jpg";
const bg19 = "/backgrounds/bg-19.jpg";
const bg20 = "/backgrounds/bg-20.jpg";
const bg21 = "/backgrounds/bg-21.jpg";
const bg22 = "/backgrounds/bg-22.jpg";
const bg23 = "/backgrounds/bg-23.jpg";
const bg24 = "/backgrounds/bg-24.jpg";
const bg25 = "/backgrounds/bg-25.jpg";
const bg26 = "/backgrounds/bg-26.jpg";
const bg27 = "/backgrounds/bg-27.jpg";
const bg28 = "/backgrounds/bg-28.jpg";
const bg29 = "/backgrounds/bg-29.jpg";
const bg30 = "/backgrounds/bg-30.jpg";
const bg31 = "/backgrounds/bg-31.jpg";
const bg32 = "/backgrounds/bg-32.jpg";
const bg33 = "/backgrounds/bg-33.jpg";
const bg34 = "/backgrounds/bg-34.jpg";
const bg35 = "/backgrounds/bg-35.jpg";
const bg36 = "/backgrounds/bg-36.jpg";
const bg37 = "/backgrounds/bg-37.jpg";
const bg38 = "/backgrounds/bg-38.jpg";
const bg39 = "/backgrounds/bg-39.jpg";
const bg40 = "/backgrounds/bg-40.jpg";
const bg41 = "/backgrounds/bg-41.jpg";
const bg42 = "/backgrounds/bg-42.jpg";
const bg43 = "/backgrounds/bg-43.jpg";
const bg44 = "/backgrounds/bg-44.jpg";
const bg45 = "/backgrounds/bg-45.jpg";
const bg46 = "/backgrounds/bg-46.jpg";
const bg47 = "/backgrounds/bg-47.jpg";
const bg48 = "/backgrounds/bg-48.jpg";

const backgroundUrls = [
bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10,
bg11, bg12, bg13, bg14, bg15, bg16, bg17, bg18, bg19, bg20,
bg21, bg22, bg23, bg24, bg25, bg26, bg27, bg28, bg29, bg30,
bg31, bg32, bg33, bg34, bg35, bg36, bg37, bg38, bg39, bg40,
bg41, bg42, bg43, bg44, bg45, bg46, bg47, bg48
];

export default backgroundUrls;